import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Some of my favorite movie -- some rated.`}</p>
    <h2>{`Underwater 9/10`}</h2>
    <h2>{`Birds of Prey 7/10`}</h2>
    <h2>{`Indiana Jones series (3/10?)`}</h2>
    <h2>{`Studio Ghibli movies`}</h2>
    <p>{`Checkout my `}<a parentName="p" {...{
        "href": "/checklists/ghibli"
      }}>{`Ghibli checklist`}</a>{` for more info. I'll be adding movies and their reviews here as I go through them and watch/rewatch them.`}</p>
    <h3>{`Princess Mononoke (10/10)`}</h3>
    <p>{`I absolutely loved this movie.`}</p>
    <h3>{`Whisper of the Heart (10/10)`}</h3>
    <p>{`It's hard to rate this movie anything other than 10. Very lovely movie.`}</p>
    <p>{`I watched a couple of fun YouTube videos afterward about the movie, just to get some perspective on how others received it:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=NyNvOVvYb84"
        }}>{`Whisper of the Heart: How Does It Feel To Be An Artist`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=GKUbnqZKd6E"
        }}>{`Why Whisper of the Heart is a Masterpiece`}</a></li>
    </ul>
    <h3>{`Kiki's Delivery Service`}</h3>
    <p>{`Very chill movie. It's like a break from life despite being about a big transition in life (moving out, finding your first job, etc.). I've seen it a dozen times but each time, it makes me very happy to see it again. :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      